import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { selectStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE, REQUIRED_FIELD } from "../../../utils/constants";
import { useContext, useEffect, useState } from "react";
import { getPedagogicalTraining } from "../../../services/getPedagogicalTraining";
import { Toast } from "../../../components/Toast";
import { getAcademicQualification } from "../../../services/getAcademicQualification";
import { getTrainingArea } from "../../../services/getTrainingArea";
import { ACADEMIC_YEAR_URL } from "../../../utils/endpoints";
import api from "../../../utils/api";
import { AuthContext } from "../../../provider/AuthProvider";

function QualificationsModal({
  setAcademicQualificationForTeacher,
  setAcademicQualificationForTeacherData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [academicYear, setAcademicYear] = useState();
  const [academicQualification, setAcademicQualification] = useState();
  const [trainingArea, setTrainingArea] = useState();
  const [pedagogicalTraining, setPedagogicalTraining] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [academicQualificationOptions, setAcademicQualificationOptions] =
    useState([]);
  const [trainingAreaOptions, setTrainingAreaOptions] = useState([]);
  const [pedagogicalTrainingOptions, setPedagogicalTrainingOptions] = useState(
    []
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  async function getAcademicYearsOptions() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        if (user?.schoolId) {
          data = data.filter((year) =>
            year.academicYearForSchools.some(
              (schoolYear) =>
                schoolYear.closed !== true &&
                schoolYear.school?.id === user?.schoolId
            )
          );
        }
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getAcademicQualificationOptions() {
    getAcademicQualification()
      .then((res) => {
        setAcademicQualificationOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getTrainingAreaOptions() {
    getTrainingArea()
      .then((res) => {
        setTrainingAreaOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getPedagogicalTrainingOptions() {
    getPedagogicalTraining()
      .then((res) => {
        setPedagogicalTrainingOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  const onChangeAcademicYear = (value) => {
    setAcademicYear(value);
  };

  const onChangeAcademicQualification = (value) => {
    setAcademicQualification(value);
  };

  const onChangeTrainingArea = (value) => {
    setTrainingArea(value);
  };

  const onChangePedagogicalTraining = (value) => {
    setPedagogicalTraining(value);
  };

  useEffect(() => {
    getAcademicYearsOptions();
    getAcademicQualificationOptions();
    getTrainingAreaOptions();
    getPedagogicalTrainingOptions();
    setIsLoaded(true);
  }, []);

  const handleAcademingQualifications = () => {
    if (
      !academicYear?.value ||
      !academicQualification?.value ||
      !trainingArea?.value ||
      !pedagogicalTraining?.value
    ) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else {
      const newAcademicQualification = {
        academicYearId: academicYear?.value,
        academicQualification: academicQualification.value,
        trainingArea: trainingArea.value,
        pedagogicalTraining: pedagogicalTraining.value,
      };

      const newAcademicQualificationData = {
        academicYear: academicYear,
        academicQualification: academicQualification,
        trainingArea: trainingArea,
        pedagogicalTraining: pedagogicalTraining,
      };

      setAcademicQualificationForTeacher((prevState) => [
        ...prevState,
        newAcademicQualification,
      ]);
      setAcademicQualificationForTeacherData((prevState) => [
        ...prevState,
        newAcademicQualificationData,
      ]);

      onClose();
    }
  };

  return (
    <Box mb={3}>
      <Button variant="secondary" onClick={onOpen}>
        Associar Habilitações Académicas
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Associar Habilitações Académicas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoaded && (
              <Stack gap={5}>
                <Box>
                  <Label title="Ano Lectivo" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={academicYearOptions}
                    onChange={onChangeAcademicYear}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Habilitação Académica" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={academicQualificationOptions}
                    onChange={onChangeAcademicQualification}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Área de Formação" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={trainingAreaOptions}
                    onChange={onChangeTrainingArea}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Formação Pedagógica" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={pedagogicalTrainingOptions}
                    onChange={onChangePedagogicalTraining}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <Button onClick={handleAcademingQualifications}>Associar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default QualificationsModal;
