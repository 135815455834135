import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import EditEnrollmentModal from "./EditEnrollmentModal";

function QualificationsAccordion({
  id,
  index,
  isDisabled,
  academicQualificationForTeacher,
  setAcademicQualificationForTeacher,
  academicQualificationForTeacherData,
  setAcademicQualificationForTeacherData,
  academicYear,
  academicQualification,
  trainingArea,
  pedagogicalTraining,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteData = () => {
    const newAcademicQualification = academicQualificationForTeacher.filter(
      (_, i) => i !== index
    );
    const newAcademicQualificationData =
      academicQualificationForTeacherData.filter((_, i) => i !== index);
    setAcademicQualificationForTeacher(newAcademicQualification);
    setAcademicQualificationForTeacherData(newAcademicQualificationData);
    onClose();
  };

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight="bold"
              fontSize="xl"
            >
              Ano Lectivo {academicYear}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel py={4}>
          <Flex justifyContent="space-between">
            <Stack spacing={8}>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Habilitação Académica
                </Text>
                {academicQualification}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Área de Formação
                </Text>
                {trainingArea}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Formação Pedagógica
                </Text>
                {pedagogicalTraining}
              </Box>
            </Stack>
            {!isDisabled && (
              <Box>
                <Button variant="secondary" onClick={onOpen} ml={4}>
                  Excluir
                </Button>
                <AlertDialog isOpen={isOpen} onClose={onClose}>
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Excluir Habilitação Académica
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Tem a certeza que pretende excluir a habilitação
                        académica?
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button variant="secondary" onClick={onClose} mr={3}>
                          Cancelar
                        </Button>
                        <Button onClick={deleteData}>Excluir</Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </Box>
            )}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default QualificationsAccordion;
