import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import TabTeacherInformation from "./TabTeacherInformation";
import TabTeacherData from "./TabTeacherData";
import FormButtons from "./FormButtons";
import { useContext, useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import TabTeacherSubjects from "./TabTeacherSubjects";
import { AuthContext } from "../../../provider/AuthProvider";
import TabAcademicQualifications from "./TabAcademicQualifications";

export default function Form({
  isDisabled,
  setIsDisabled,
  id,
  register,
  handleSubmit,
  errors,
  control,
  watch,
  setValue,
  unregister,
  createTeacher,
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
  academicQualificationForTeacher,
  setAcademicQualificationForTeacher,
  academicQualificationForTeacherData,
  setAcademicQualificationForTeacherData,
  isActive,
  editTeacher,
  cancelEdit,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { addToast } = Toast();
  const [navigationDisabled, setNavigationDisabled] = useState(true);
  const [tabDisabled, setTabDisabled] = useState(true);
  const [fieldIsDisabled, setFieldIsDisabled] = useState(false);

  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const name = watch("name");

  useEffect(() => {
    if (!isDisabled && id) {
      setNavigationDisabled(false);
      setTabDisabled(false);
    }
  }, [isDisabled, id]);

  useEffect(() => {
    if (user.schoolId && contractualBondRequestsData.length > 0) {
      if (contractualBondRequestsData.some((bond) => !bond.isEditable)) {
        setFieldIsDisabled(true);
      }
    }
  }, [contractualBondRequestsData]);

  const handleTabChange = (index) => {
    if (!navigationDisabled) {
      if (index !== 2 && index !== 3) {
        setActiveTab(index);
      } else {
        if (!tabDisabled) {
          setActiveTab(index);
        }
      }
    }
  };

  const onSubmit = (values, event) => {
    const action = event.nativeEvent.submitter.name;
    if (action === "paginate") {
      setNavigationDisabled(false);
      setActiveTab(1);
      return true;
    } else if (action === "newstep") {
      if (academicQualificationForTeacher.length > 0) {
        setTabDisabled(false);
        setActiveTab(2);
      } else {
        setTabDisabled(true);
        addToast({
          title: "Obrigatório associar habilitações académicas",
          status: "error",
        });
      }
      return true;
    } else if (action === "step") {
      if (contractualBondRequests.length > 0) {
        setTabDisabled(false);
        setActiveTab(3);
      } else {
        setTabDisabled(true);
        addToast({
          title: "Obrigatório associar vínculo e actividade profissional",
          status: "error",
        });
      }
      return true;
    } else if (action === "create") {
      if (!academicQualificationForTeacher.length > 0) {
        setActiveTab(1);
        addToast({
          title: "Obrigatório associar habilitações académicas",
          status: "error",
        });
        return true;
      }
      if (!contractualBondRequests.length > 0) {
        setActiveTab(2);
        addToast({
          title: "Obrigatório associar vínculo e actividade profissional",
          status: "error",
        });
        return true;
      }
      const requiresBinding = contractualBondRequests.some(
        (el) =>
          el.teachingFunctionId === "1" &&
          contractualBondRequests.some(
            (i) => !i.coursesBound?.length && !i.subjectsBound?.length
          )
      );
      if (requiresBinding) {
        addToast({
          title: "Obrigatório associar cursos e disciplinas",
          status: "error",
        });
      } else {
        setIsDisabled(true);
        if (id) {
          editTeacher();
        }
      }
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Heading as="h1">
          {isDisabled
            ? `Resumo | ${name}`
            : id
            ? `Editar ${name}`
            : `Cadastrar Docente`}
        </Heading>
        {isDisabled ? (
          <Stack gap={5}>
            <TabTeacherInformation
              isDisabled={isDisabled}
              register={register}
              errors={errors}
              control={control}
              watch={watch}
            />
            <TabAcademicQualifications
              isDisabled={isDisabled}
              id={id}
              academicQualificationForTeacherData={
                academicQualificationForTeacherData
              }
            />
            <TabTeacherData
              isDisabled={isDisabled}
              id={id}
              contractualBondRequestsData={contractualBondRequestsData}
            />
            <TabTeacherSubjects
              isDisabled={isDisabled}
              id={id}
              contractualBondRequestsData={contractualBondRequestsData}
            />
          </Stack>
        ) : (
          <>
            <Tabs index={activeTab} onChange={handleTabChange}>
              <TabList>
                <Tab>Informações do Docente</Tab>
                <Tab>Habilitações Académicas</Tab>
                <Tab>Vínculo e Actividade Profissional</Tab>
                <Tab>Cursos e Disciplinas</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0} pt="20px">
                  <TabTeacherInformation
                    isDisabled={isDisabled}
                    register={register}
                    errors={errors}
                    control={control}
                    watch={watch}
                    handleTabChange={handleTabChange}
                    setValue={setValue}
                    fieldIsDisabled={fieldIsDisabled}
                  />
                </TabPanel>
                <TabPanel p={0} pt="20px">
                  <TabAcademicQualifications
                    isDisabled={isDisabled}
                    id={id}
                    handleTabChange={handleTabChange}
                    academicQualificationForTeacher={
                      academicQualificationForTeacher
                    }
                    setAcademicQualificationForTeacher={
                      setAcademicQualificationForTeacher
                    }
                    academicQualificationForTeacherData={
                      academicQualificationForTeacherData
                    }
                    setAcademicQualificationForTeacherData={
                      setAcademicQualificationForTeacherData
                    }
                  />
                </TabPanel>
                <TabPanel p={0} pt="20px">
                  <TabTeacherData
                    isDisabled={isDisabled}
                    id={id}
                    handleTabChange={handleTabChange}
                    contractualBondRequests={contractualBondRequests}
                    setContractualBondRequests={setContractualBondRequests}
                    contractualBondRequestsData={contractualBondRequestsData}
                    setContractualBondRequestsData={
                      setContractualBondRequestsData
                    }
                    academicQualificationForTeacher={
                      academicQualificationForTeacher
                    }
                    academicQualificationForTeacherData={
                      academicQualificationForTeacherData
                    }
                  />
                </TabPanel>
                <TabPanel p={0} pt="20px">
                  <TabTeacherSubjects
                    isDisabled={isDisabled}
                    id={id}
                    handleTabChange={handleTabChange}
                    contractualBondRequests={contractualBondRequests}
                    setContractualBondRequests={setContractualBondRequests}
                    contractualBondRequestsData={contractualBondRequestsData}
                    setContractualBondRequestsData={
                      setContractualBondRequestsData
                    }
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
        <FormButtons
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          id={id}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          createTeacher={createTeacher}
          cancelEdit={cancelEdit}
          isActive={isActive}
        />
      </Stack>
    </Box>
  );
}
