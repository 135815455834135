import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import EditEnrollmentModal from "./EditEnrollmentModal";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function DataAccordion({
  id,
  index,
  isDisabled,
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
  academicYear,
  school,
  schoolId,
  contractualBondType,
  probationaryPeriod,
  teachingFunction,
  administrativePosition,
  isEditable,
  academicQualificationForTeacher,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const deleteData = () => {
    const newContractualBondRequest = contractualBondRequests.filter(
      (_, i) => i !== index
    );
    const newContractualBondRequestsData = contractualBondRequestsData.filter(
      (_, i) => i !== index
    );
    setContractualBondRequests(newContractualBondRequest);
    setContractualBondRequestsData(newContractualBondRequestsData);
    onClose();
  };

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight="bold"
              fontSize="xl"
            >
              Ano Lectivo {academicYear}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel py={4}>
          <Flex justifyContent="space-between">
            <Stack spacing={8}>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Escola
                </Text>
                {school}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Vínculo
                </Text>
                {contractualBondType}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Período Probatório
                </Text>
                {probationaryPeriod}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Funções Lectivas
                </Text>
                {teachingFunction}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Cargo não lectivo exercido
                </Text>
                {administrativePosition}
              </Box>
            </Stack>
            {!isDisabled &&
              (user?.schoolId && user?.schoolId !== schoolId ? (
                <></>
              ) : (
                <Box>
                  {isEditable && (
                    <>
                      <EditEnrollmentModal
                        id={id}
                        index={index}
                        contractualBondRequests={contractualBondRequests}
                        setContractualBondRequests={setContractualBondRequests}
                        contractualBondRequestsData={
                          contractualBondRequestsData
                        }
                        setContractualBondRequestsData={
                          setContractualBondRequestsData
                        }
                        academicQualificationForTeacher={
                          academicQualificationForTeacher
                        }
                      />
                      <Button variant="secondary" onClick={onOpen} ml={4}>
                        Excluir
                      </Button>
                      <AlertDialog isOpen={isOpen} onClose={onClose}>
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              Excluir Vínculo e Actividade Profissional
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              Tem a certeza que pretende excluir o vínculo e
                              actividade profissional?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button
                                variant="secondary"
                                onClick={onClose}
                                mr={3}
                              >
                                Cancelar
                              </Button>
                              <Button onClick={deleteData}>Excluir</Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </>
                  )}
                </Box>
              ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default DataAccordion;
