import { Accordion, Box, Heading, Stack } from "@chakra-ui/react";
import { formStyles } from "../../../utils/styles";
import QualificationsModal from "./QualificationsModal";
import QualificationsAccordion from "./QualificationsAccordion";

function TabAcademicQualifications({
  isDisabled,
  id,
  academicQualificationForTeacher,
  setAcademicQualificationForTeacher,
  academicQualificationForTeacherData,
  setAcademicQualificationForTeacherData,
}) {
  return (
    <Box>
      <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
        Habilitações Académicas
      </Heading>
      {!isDisabled && (
        <QualificationsModal
          setAcademicQualificationForTeacher={
            setAcademicQualificationForTeacher
          }
          setAcademicQualificationForTeacherData={
            setAcademicQualificationForTeacherData
          }
        />
      )}
      <Box sx={formStyles}>
        <>
          {academicQualificationForTeacherData &&
            academicQualificationForTeacherData.length > 0 && (
              <Stack spacing={5}>
                <Accordion allowToggle>
                  {academicQualificationForTeacherData.map((i, index) => (
                    <QualificationsAccordion
                      key={index}
                      id={id}
                      index={index}
                      isDisabled={isDisabled}
                      academicQualificationForTeacher={
                        academicQualificationForTeacher
                      }
                      setAcademicQualificationForTeacher={
                        setAcademicQualificationForTeacher
                      }
                      academicQualificationForTeacherData={
                        academicQualificationForTeacherData
                      }
                      setAcademicQualificationForTeacherData={
                        setAcademicQualificationForTeacherData
                      }
                      academicYear={
                        i.academicYear?.year || i.academicYear?.label
                      }
                      academicQualification={
                        i.academicQualification?.name ||
                        i.academicQualification?.label
                      }
                      trainingArea={
                        i.trainingArea?.name || i.trainingArea?.label
                      }
                      pedagogicalTraining={
                        i.pedagogicalTraining?.name ||
                        i.pedagogicalTraining?.label
                      }
                    />
                  ))}
                </Accordion>
              </Stack>
            )}
        </>
      </Box>
    </Box>
  );
}

export default TabAcademicQualifications;
