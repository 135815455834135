import { Accordion, Box, Heading, Stack } from "@chakra-ui/react";
import { formStyles } from "../../../utils/styles";
import EnrollmentModal from "./EnrollmentModal";
import DataAccordion from "./DataAccordion";

function TabTeacherData({
  isDisabled,
  id,
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
  academicQualificationForTeacher,
  academicQualificationForTeacherData,
}) {
  return (
    <Box>
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Vínculo e Actividade Profissional
        </Heading>
        {!isDisabled && (
          <EnrollmentModal
            contractualBondRequests={contractualBondRequests}
            setContractualBondRequests={setContractualBondRequests}
            contractualBondRequestsData={contractualBondRequestsData}
            setContractualBondRequestsData={setContractualBondRequestsData}
            academicQualificationForTeacher={academicQualificationForTeacher}
            academicQualificationForTeacherData={
              academicQualificationForTeacherData
            }
          />
        )}
      </Box>
      <Box sx={formStyles}>
        <>
          {contractualBondRequestsData &&
            contractualBondRequestsData.length > 0 && (
              <Stack spacing={5}>
                <Accordion allowToggle>
                  {contractualBondRequestsData.map((i, index) => (
                    <DataAccordion
                      key={index}
                      id={id}
                      index={index}
                      isDisabled={isDisabled}
                      contractualBondRequests={contractualBondRequests}
                      setContractualBondRequests={setContractualBondRequests}
                      contractualBondRequestsData={contractualBondRequestsData}
                      setContractualBondRequestsData={
                        setContractualBondRequestsData
                      }
                      academicYear={
                        i.academicYearBonded?.year ||
                        i.academicYearBonded?.label
                      }
                      school={i.schoolBonded?.name || i.schoolBonded?.label}
                      schoolId={i.schoolBonded?.id || i.schoolBonded?.value}
                      contractualBondType={
                        i.contractualBondTypeBonded?.name ||
                        i.contractualBondTypeBonded?.label
                      }
                      probationaryPeriod={
                        (i.probationaryPeriodBonded?.id ||
                          i.probationaryPeriodBonded) == 1
                          ? "Sim"
                          : "Não"
                      }
                      teachingFunction={
                        (i.teachingFunctionBonded?.id ||
                          i.teachingFunctionBonded) == 1
                          ? "Sim"
                          : "Não"
                      }
                      administrativePosition={i.administrativePositionBonded
                        ?.map((i) => i.name || i.label)
                        ?.join(", ")}
                      isEditable={!id ? true : i.isEditable}
                      academicQualificationForTeacher={
                        academicQualificationForTeacher
                      }
                    />
                  ))}
                </Accordion>
              </Stack>
            )}
        </>
      </Box>
    </Box>
  );
}

export default TabTeacherData;
